<script setup>
import { onBeforeMount, ref, reactive } from "vue";
import { useRoute } from "vue-router/auto";
import ClientLayout from "@/layouts/ClientLayout.vue";
import useClients from "@/hooks/useClients";
import { useToast } from "vue-toastification";
import router from "@/router";


const route = useRoute();
const toast = useToast();
const clientData = reactive({
    name: "",
    nickname: "",
    website: "",
    email: "",
    billing_email: "",
    address_1: "",
    city: "",
    state: "",
    zip: null,
    logo_url: "",
})

const { client, fetchClient, updateClient } = useClients()

onBeforeMount(async () => {
    await fetchClient(route.params.id);
    if (client) {
        clientData.name = client.value.name;
        clientData.nickname = client.value.nickname;
        clientData.website = client.value.website;
        clientData.email = client.value.email;
        clientData.billing_email = client.value.billing_email
        clientData.address_1 = client.value.address_1;
        clientData.city = client.value.city;
        clientData.state = client.value.state;
        clientData.zip = client.value.zip;
        clientData.logo_url = client.value.logo_url;
    }
});

const save = async () => {
    await updateClient(route.params.id, clientData);
    toast.success("Client updated successfully.");
}

</script>

<template>
  <ClientLayout :client="client">
    <div class="w-100">
        <div class="px-4" v-if="client">
            <v-card class="pa-4">
                <div v-if="client.logo_url" class="d-flex justify-center">
                    <v-img 
                    class="border rounded"
                    :max-width="200"
                    :max-height="200"
                    :src="client.logo_url" />
                </div>
                <v-card-title class="text-center title-text-size mb-4">{{ client.name }}</v-card-title>
                <v-row class="mb-1">
                    <v-col cols="6">
                        <label>COMPANY NAME</label>
                        <v-text-field
                            v-model="clientData.name"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="6">
                        <label>COMPANY NICKNAME</label>
                        <v-text-field
                            v-model="clientData.nickname"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="6">
                        <label>WEBSITE</label>
                        <v-text-field
                            v-model="clientData.website"
                            prefix="https"
                            placeholder="website"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="6">
                        <label>EMAIL</label>
                        <v-text-field
                            v-model="clientData.email"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="12">
                        <label>BILLING EMAIL <span class="note-text-size font-italic pl-2">(Separate multiple addresses using a comma.)</span></label>
                        <v-text-field
                            v-model="clientData.billing_email"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="12">
                        <label>ADDRESS</label>
                        <v-text-field
                            v-model="clientData.address_1"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="12" md="6">
                        <label>CITY</label>
                        <v-text-field
                            v-model="clientData.city"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="4" md="2">
                        <label>STATE</label>
                        <v-text-field
                            v-model="clientData.state"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="8" md="4">
                        <label>ZIP</label>
                        <v-text-field
                            v-model="clientData.zip"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                    <v-col cols="12">
                        <label>LOGO URL</label>
                        <v-text-field
                            v-model="clientData.logo_url"
                            prefix="https"
                            density="compact"
                            variant="outlined"
                            hide-details="auto"/>
                    </v-col>
                </v-row>
            </v-card>


            <div class="d-flex justify-end mt-4">
                <v-btn 
                    size="large" 
                    variant="flat" 
                    color="primary"
                    @click="save"
                    >
                    Save
                </v-btn>
            </div>
        </div>
    </div>
  </ClientLayout>
</template>

<style scoped>
.title-text-size {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.05;
    letter-spacing: normal;
}
.note-text-size {
    font-size: 0.8rem;
}
</style>